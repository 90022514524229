<template>
  <div class="page-bottom-box">
    <div class="desc-text">
      {{ language.homePage.bottom.text.recordText }}
    </div>
    <div class="media-list">
      <div class="state-media-item" v-for="item in mediaList" :key="item.name" @click="jumpUrl(item.url)">
        <img :src="require('../assets/imgs/' + item.icon)" alt="" @mouseover="changeImgUrl($event, item.hicon)" @mouseleave="changeImgUrl($event, item.icon)" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      language: {},
      mediaList: [
        {
          icon: "i_ins.png", // 默认图片
          hicon: "i_hins.png", // 鼠标移入高亮图片
          url: "https://www.instagram.com/Onion_app/",
        },
        {
          icon: "i_fb.png",
          hicon: "i_hfb.png",
          url: "https://www.facebook.com/Onion_App-102384495645242",
        },
        {
          icon: "i_tw.png",
          hicon: "i_htw.png",
          url: "https://twitter.com/OnionApp",
        },
        {
          icon: "i_ytb.png",
          hicon: "i_hytb.png",
          url: "https://www.youtube.com/channel/UCx7BANqTq5CnKYnPSMGLAng",
        },
        {
          icon: "i_dy.png",
          hicon: "i_hdy.png",
          url: "https://www.tiktok.com/@no_insomnia555",
        },
      ],
    };
  },
  created() {
    this.getCurLanguage();
    window.addEventListener("setItem", e => {
      this.getCurLanguage();
    });
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    // 获取当前语言
    getCurLanguage() {
      let curLang = localStorage.getItem("language") || "english";
      this.language = Object.assign({}, this.LANGUAGES[curLang]);
    },

    // 图片移入移出事件
    changeImgUrl(ev, url) {
      ev.target.src = require("../assets/imgs/" + url);
    },

    // 跳转到官媒
    jumpUrl(url) {
      var a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("target", "_blank");
      a.setAttribute("id", "camnpr");
      document.body.appendChild(a);
      a.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.page-bottom-box {
  width: 100%;
  @include color(bottom-bg, "background");
  padding: 0.24rem 0;
  .desc-text {
    width: 100%;
    text-align: center;
    @include color(bottom-color);
    font-size: $fs-14;
  }
  .media-list {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: 0 auto;
    margin-top: 0.2rem;
    .state-media-item {
      flex: 1;
      margin-right: 0.15rem;
      &:last-child {
        margin-right: 0;
      }
      img {
        cursor: pointer;
        width: 100%;
      }
    }
  }
}
</style>
