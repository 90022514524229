import Vue from "vue";
import Router from "vue-router";

import notFound from "@/common/components/404.vue";
import homeView from "../views/index/index.vue";
import H5 from "../views/index/H5.vue";
import plus from "../views/index/plus.vue";
import gold from "../views/index/gold.vue";
import golds from "../views/index/golds.vue";
import premium from "../views/index/premium.vue";
import html from "../views/index/html.vue";

Vue.use(Router);

export const router = [
  /* {
    path: "/",
    redirect: "index",
  }, */
  {
    path: "/",
    name: "index",
    component: homeView,
    meta: {
      title: "Onion",
    },
  },
  {
    path: "/H5",
    name: "H5",
    component: H5,
    meta: {
      title: "Onion",
    },
  },
  {
    path: "/plus",
    name: "plus",
    component: plus,
    meta: {
      title: "Onion",
    },
  },
  {
    path: "/gold",
    name: "gold",
    component: gold,
    meta: {
      title: "Onion",
    },
  },
  {
    path: "/golds",
    name: "golds",
    component: golds,
    meta: {
      title: "Onion",
    },
  },
  {
    path: "/premium",
    name: "premium",
    component: premium,
    meta: {
      title: "Onion",
    },
  },
  {
    path: "/html/:pageType?",
    name: "html",
    component: html,
    meta: {
      title: "Onion",
    },
  },
  {
    path: "*", //通常使用通配符进行配置404页面
    component: notFound,
  },
];

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: router,
});
