import "babel-polyfill"; // IE兼容
import Vue from "vue";
import App from "./App";
import router from "./router";
import comfn from "@/common/js/comfn";
import "./assets/fonts/iconfont.js"; // SVG本地图片

import Vant from "vant";
import "vant/lib/index.css";

import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
// import "./fullpage.scrollHorizontally.min"; // Optional. When using fullpage extensions
import VueFullPage from "vue-fullpage.js";

Vue.use(Vant);
Vue.use(VueFullPage);

const LANGUAGES = {
  chinese: require("@/common/statics/language/chinese/chinese.json"),
  english: require("@/common/statics/language/english/english.json"),
  turkish: require("@/common/statics/language/turkish/turkish.json"),
  thai: require("@/common/statics/language/thai/thai.json"),
};
const LANGDISC = [
  {
    name: "中文", // name字段给ActionSheet组件使用的
    text: "中文", // text字段给DropdownMenu组件使用的
    value: "chinese",
  },
  {
    name: "En",
    text: "En",
    value: "english",
  },
  {
    name: "Türkçe",
    text: "Türkçe",
    value: "turkish",
  },
  {
    name: "ภาษาไทย",
    text: "ภาษาไทย",
    value: "thai",
  },
];

Vue.prototype.COMFN = comfn; // 全局直接使用
Vue.prototype.LANGUAGES = LANGUAGES; // 全局语言包
Vue.prototype.LANGDISC = LANGDISC; // 切换语言包下拉框数据

// 监听sessionStorage的变化
Vue.prototype.resetSetItem = (key, newVal) => {
  let arr = [
    "scrollPageIndex", // 当前滚动到哪个屏幕
  ];
  if (arr.indexOf(key) > -1) {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
      setItem(k, val) {
        sessionStorage.setItem(k, val);

        // 初始化创建的事件
        newStorageEvent.initStorageEvent("setItem", false, false, k, null, val, null, null);

        // 派发对象
        window.dispatchEvent(newStorageEvent);
      },
    };
    return storage.setItem(key, newVal);
  }
};

new Vue({
  router,
  render: h => h(App),
}).$mount("#app");
