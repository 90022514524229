<template>
  <div class="safety-box" :class="!isH5 ? 'web-safety' : ''">
    <div class="safety-container">
      <div class="contract-box">
        <IframeComps :key="iframeSrc" :src="iframeSrc" />
      </div>
      <!-- 底部 -->
      <template v-if="isH5">
        <H5bottom />
      </template>
      <template v-else>
        <Bottom />
      </template>
    </div>
  </div>
</template>
<script>
import Bottom from "../../components/bottom.vue";
import H5bottom from "../../components/H5bottom.vue";
import IframeComps from "@/common/components/iframe.vue";
export default {
  components: { H5bottom, Bottom, IframeComps },
  data() {
    return {
      iframeHeight: 0,
      isH5: false, // 是否是H5
      curLang: "english", // 当前语言
      iframeSrc: "",
      srcDist: {
        community: {
          chinese: "/html/community/community_zh.htm",
          english: "/html/community/community_en.htm",
          turkish: "/html/community/community_tr.htm",
          thai: "/html/community/community_th.htm",
        },
        safety: {
          chinese: "/html/safety/safety_zh.htm",
          english: "/html/safety/safety_en.htm",
          turkish: "/html/safety/safety_tr.htm",
          thai: "/html/safety/safety_th.htm",
        },
        terms: {
          chinese: "/html/terms/terms_zh.htm",
          english: "/html/terms/terms_en.htm",
          turkish: "/html/terms/terms_tr.htm",
          thai: "/html/terms/terms_th.htm",
        },
        cookie: {
          chinese: "/html/cookie/cookie_zh.htm",
          english: "/html/cookie/cookie_en.htm",
          turkish: "/html/cookie/cookie_tr.htm",
          thai: "/html/cookie/cookie_th.htm",
        },
        privacy: {
          chinese: "/html/privacy/privacy_zh.htm",
          english: "/html/privacy/privacy_en.htm",
          turkish: "/html/privacy/privacy_tr.htm",
          thai: "/html/privacy/privacy_th.htm",
        },
      },
    };
  },
  created() {
    this.getCurLanguage();
    window.addEventListener("setItem", e => {
      this.getCurLanguage();
    });
    // 根据屏幕宽度自适应显示N个菜单
    window.addEventListener("resize", () => {
      //this.$nextTick(() => {
      this.checkWidth();
      //});
    });
  },
  mounted() {
    this.checkWidth();
  },
  computed: {
    pageType() {
      let params = this.$route.params;
      return params.pageType;
    },
  },
  watch: {
    curLang: {
      handler(val) {
        this.iframeSrc = this.srcDist[this.pageType][val];
      },
      immediate: true,
    },
  },
  methods: {
    // 获取当前语言
    getCurLanguage() {
      let curLang = localStorage.getItem("language");
      curLang = curLang != "null" ? curLang : "english";
      this.curLang = curLang;
    },

    // 当前是否是H5版本
    checkWidth() {
      let clientWidth = document.documentElement.clientWidth || document.body.clientWidth; // 当前屏幕宽度

      if (clientWidth <= 1024) {
        this.isH5 = true;
      } else {
        this.isH5 = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.safety-box {
  width: 100%;
  @include color(bg, "background");
  &.web-safety {
    .safety-container {
      width: 100%;
      padding-top: 1rem;
      .contract-box {
        width: 70%;
        margin: 0 auto;
      }
    }
  }
  // 默认是H5的样式
  .safety-container {
    width: 100%;
    padding-top: 0.6rem;
    .contract-box {
      width: 90%;
      margin: 0 auto;
    }
  }
}
</style>
