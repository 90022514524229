<template>
  <div class="golds-box">
    <div class="golds-container" v-if="JSON.stringify(language) != '{}'">
      <div class="section">
        <div class="section-cnt-box">
          <div class="img-cnt-box" :class="!isH5 ? 'web-golds' : ''">
            <div class="text-cnt-box">
              <div class="title-box">
                {{ language.homePage.Golds.title }}
              </div>
              <div class="text-box">
                {{ language.homePage.Golds.content }}
              </div>
              <div class="btn-item btn-joinnow" v-if="isH5" @click="doDownload">
                <span class="btn-text">{{ language.homePage.Golds.joinText }}</span>
                <van-icon class="btn-icon" name="down" />
              </div>
            </div>
            <div class="img-box">
              <img class="bn-img" src="../../assets/imgs/goldsimg1.png" alt="" />
            </div>
            <div class="trait-list">
              <ul>
                <li class="trait-item" v-for="item in language.homePage.Golds.trait" :key="item.title">
                  <div class="item-box">
                    <div class="item-icon">
                      <img :src="require('../../assets/imgs/' + item.icon)" alt="" />
                    </div>
                    <div class="item-tit-cnt">
                      <div class="item-title">{{ item.title }}</div>
                      <div class="item-cnt">{{ item.desc }}</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 底部 -->
        <template v-if="isH5">
          <H5bottom />
        </template>
        <template v-else>
          <Bottom />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import Bottom from "../../components/bottom.vue";
import H5bottom from "../../components/H5bottom.vue";
export default {
  components: { H5bottom, Bottom },
  data() {
    return {
      isH5: false, // 是否是H5
      language: {}, // 当前语言包数据
      isIos: false, // 是否是IOS设备
    };
  },
  created() {
    this.getCurLanguage();
    window.addEventListener("setItem", e => {
      this.getCurLanguage();
    });
    // 根据屏幕宽度自适应显示N个菜单
    window.addEventListener("resize", () => {
      //this.$nextTick(() => {
      this.checkWidth();
      //});
    });
  },
  mounted() {
    this.checkWidth();
  },
  methods: {
    // 获取当前语言
    getCurLanguage() {
      let curLang = localStorage.getItem("language");
      curLang = curLang != "null" ? curLang : "english";
      this.language = Object.assign({}, this.LANGUAGES[curLang]);
    },

    // 当前是否是H5版本
    checkWidth() {
      let clientWidth = document.documentElement.clientWidth || document.body.clientWidth; // 当前屏幕宽度

      if (clientWidth <= 1024) {
        this.isH5 = true;
      } else {
        this.isH5 = false;
      }
    },

    // 点击下载
    doDownload() {
      // 判断是安卓还是ios
      let url = "";
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS) {
        url = this.language.homePage.download.apple.url;
      } else {
        url = this.language.homePage.download.google.url;
      }
      window.open(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.golds-box {
  width: 100%;
  .golds-container {
    width: 100%;
    .section {
      padding-top: 0.8rem;
      width: 100%;
      @include color(color);
      @include color(card-bg, "background");
      .section-cnt-box {
        width: 100%;
        margin: 0 auto;
        .btn-item {
          cursor: pointer;
          font-size: $fs-16;
          @include color(themes1, "background");
          @include color(btn-color);
          width: 1.62rem;
          margin: 0.2rem auto;
          text-align: center;
          border-radius: 0.24rem;
          padding: 0.14rem 0;
          font-weight: bold;
          .btn-icon {
            font-weight: bold;
            margin-left: 0.08rem;
            transform: rotate(-90deg);
          }
        }
        .img-cnt-box {
          width: 100%;
          &.web-golds {
            margin-top: 0.4rem;
            .text-cnt-box {
              width: 100%;
              .title-box {
                font-size: $fs-48;
                width: 58.3%;
                margin-bottom: 0.32rem;
                text-align: center;
              }
              .text-box {
                width: 58.3%;
                line-height: 0.34rem;
                font-size: $fs-22;
                text-align: center;
              }
            }
            .img-box {
              width: 52.8%;
              font-size: 0;
              .bn-img {
                width: 100%;
              }
            }
            .trait-list {
              margin-top: 0.2rem;
              width: 52.8%;
              ul {
                li.trait-item {
                  .item-box {
                    padding: 0.24rem;
                    .item-icon {
                      width: 0.72rem;
                    }
                    .item-tit-cnt {
                      width: 80%;
                      .item-title {
                        font-size: $fs-26;
                        margin-bottom: 0.08rem;
                      }
                      .item-cnt {
                        font-size: $fs-20;
                      }
                    }
                  }
                }
              }
            }
            .more-text {
              width: 52.8%;
              font-size: $fs-20;
            }
            .btn-getstart {
              margin-top: 0.4rem;
              margin-bottom: 0.64rem;
            }
          }
          .text-cnt-box {
            width: 100%;
            .title-box {
              font-size: $fs-30;
              @include color(title);
              font-weight: bold;
              width: 83%;
              margin: 0 auto;
              margin-bottom: 0.14rem;
            }
            .text-box {
              font-size: $fs-16;
              line-height: 0.24rem;
              @include color(color);
              width: 83%;
              margin: 0 auto;
              margin-bottom: 0.4rem;
            }
          }
          .img-box {
            width: 100%;
            margin: 0 auto;
            font-size: 0;
            .bn-img {
              width: 100%;
              /* transition: all 0.3s ease 0s;
              &:hover {
                transform: scale(1.2);
              } */
            }
          }
          .trait-list {
            width: 83%;
            margin: 0 auto;
            margin-bottom: 0.6rem;
            ul {
              width: 100%;
              li.trait-item {
                width: 100%;
                margin-bottom: 0.12rem;
                .item-box {
                  padding: 0.16rem;
                  display: flex;
                  align-items: center;
                  @include color(bg2, "background");
                  border-radius: 0.16rem;
                  .item-icon {
                    margin-right: 0.12rem;
                    width: 0.48rem;
                    font-size: 0;
                    img {
                      width: 100%;
                    }
                  }
                  .item-tit-cnt {
                    width: 80%;
                    .item-title {
                      font-size: $fs-16;
                      @include color(title);
                      margin-bottom: 0.04rem;
                      font-weight: bold;
                    }
                    .item-cnt {
                      font-size: $fs-12;
                      @include color(desc);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
