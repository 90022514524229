<template>
  <div class="premium-box bg2">
    <div class="premium-container" v-if="JSON.stringify(language) != '{}'" :class="!isH5 ? 'web-premium' : ''">
      <div class="section">
        <div class="section-cnt-box">
          <div class="img-cnt-box">
            <div class="text-cnt-box bg">
              <div class="title-box">
                {{ language.homePage.Premium.title }}
              </div>
              <div class="text-box">
                {{ language.homePage.Premium.content }}
              </div>
            </div>
            <div class="text-cnt-box bg2">
              <div class="txtcnt-box clearboth">
                <div class="right-text-box">
                  <div class="title-box">
                    {{ language.homePage.Premium.title2 }}
                  </div>
                  <div class="text-box">
                    {{ language.homePage.Premium.content2 }}
                  </div>
                </div>
                <div class="img-box">
                  <img class="bn-img" src="../../assets/imgs/premiumimg1.png" alt="" />
                </div>
              </div>
            </div>
            <div class="text-cnt-box bg2">
              <div class="txtcnt-box clearboth">
                <div class="right-text-box">
                  <div class="title-box">
                    {{ language.homePage.Premium.title3 }}
                  </div>
                  <div class="text-box">
                    {{ language.homePage.Premium.content3 }}
                  </div>
                </div>
                <div class="img-box">
                  <img class="bn-img" src="../../assets/imgs/premiumimg2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 底部 -->
        <template v-if="isH5">
          <H5bottom />
        </template>
        <template v-else>
          <Bottom />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import Bottom from "../../components/bottom.vue";
import H5bottom from "../../components/H5bottom.vue";
export default {
  components: { H5bottom, Bottom },
  data() {
    return {
      isH5: false, // 是否是H5
      language: {}, // 当前语言包数据
    };
  },
  created() {
    this.getCurLanguage();
    window.addEventListener("setItem", e => {
      this.getCurLanguage();
    });
    // 根据屏幕宽度自适应显示N个菜单
    window.addEventListener("resize", () => {
      //this.$nextTick(() => {
      this.checkWidth();
      //});
    });
  },
  mounted() {
    this.checkWidth();
  },
  methods: {
    // 获取当前语言
    getCurLanguage() {
      let curLang = localStorage.getItem("language");
      curLang = curLang != "null" ? curLang : "english";
      this.language = Object.assign({}, this.LANGUAGES[curLang]);
    },

    // 当前是否是H5版本
    checkWidth() {
      let clientWidth = document.documentElement.clientWidth || document.body.clientWidth; // 当前屏幕宽度

      if (clientWidth <= 1024) {
        this.isH5 = true;
      } else {
        this.isH5 = false;
      }
    },

    // 跳转到gold会员页
    jumpGold() {
      this.$router.push("/gold");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  @include color(card-bg, "background");
}
.bg2 {
  @include color(bg2, "background");
}
.premium-box {
  width: 100%;
  height: 100%;
  .premium-container {
    width: 100%;
    &.web-premium {
      .section {
        .section-cnt-box {
          .img-cnt-box {
            .text-cnt-box {
              width: 100%;
              &.bg {
                padding-top: 1rem;
                .text-box {
                  margin-bottom: 0;
                  padding-bottom: 0.64rem;
                }
              }
              .title-box {
                font-size: $fs-48;
                width: 58.3%;
                margin-bottom: 0.32rem;
                text-align: center;
              }
              .text-box {
                width: 58.3%;
                line-height: 0.34rem;
                font-size: $fs-22;
                text-align: center;
                margin-bottom: 0.64rem;
              }
              .txtcnt-box {
                width: 58.3%;
                margin: 0 auto;
                .right-text-box {
                  float: right;
                  width: 65%;
                  padding-top: 8%;
                  .title-box {
                    font-size: $fs-34;
                    width: 100%;
                    text-align: left;
                    margin-bottom: 0.22rem;
                  }
                  .text-box {
                    width: 100%;
                    text-align: left;
                    margin-bottom: 0.45rem;
                  }
                  .btn-item {
                    margin-left: 0;
                    margin-right: 0;
                  }
                }
                .img-box {
                  float: left;
                  width: 35%;
                  font-size: 0;
                  .bn-img {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .section {
      padding-top: 0.56rem;
      width: 100%;
      @include color(color);
      .section-cnt-box {
        width: 100%;
        margin: 0 auto;
        .btn-item {
          cursor: pointer;
          font-size: $fs-16;
          @include color(themes1, "background");
          @include color(btn-color);
          width: 2.4rem;
          margin: 0.2rem auto;
          text-align: center;
          border-radius: 0.24rem;
          padding: 0.18rem 0;
          font-weight: bold;
          .btn-icon {
            font-weight: bold;
            margin-left: 0.08rem;
            transform: rotate(-90deg);
          }
        }
        .img-cnt-box {
          width: 100%;
          .text-cnt-box {
            &.bg {
              padding-top: 0.2rem;
              .text-box {
                margin-bottom: 0;
                padding-bottom: 0.32rem;
              }
            }
            &.bg2 {
              padding-top: 0.4rem;
              &:last-child {
                padding-bottom: 0.6rem;
              }
            }
            width: 100%;
            .title-box {
              font-size: $fs-30;
              @include color(title);
              text-align: center;
              font-weight: bold;
              width: 83%;
              margin: 0 auto;
              margin-bottom: 0.14rem;
            }
            .text-box {
              font-size: $fs-16;
              line-height: 0.24rem;
              @include color(color);
              text-align: center;
              width: 83%;
              margin: 0 auto;
              margin-bottom: 0.4rem;
            }
          }
          .img-box {
            width: 100%;
            margin: 0 auto;
            font-size: 0;
            overflow: hidden;
            .bn-img {
              width: 100%;
            }
          }
        }
      }
      /* ::v-deep .page-bottom-box {
        position: fixed;
        bottom: 0;
        left: 0;
      } */
    }
  }
}
</style>
