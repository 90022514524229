<template>
  <div class="h5-topnav-box" :class="isIndex && HomeScroll < lightMaxHeight ? 'light' : ''">
    <div class="topnav-cnt-box">
      <!-- H5的左侧导航菜单 -->
      <div class="left-menu-box">
        <van-icon class="btn-showMenu" name="wap-nav" @click="isShowMenu = !isShowMenu" />
        <van-popup class="menu-modal" v-model="isShowMenu" position="left">
          <div class="top-btn">
            <van-icon class="close-menu-modal" name="cross" @click="isShowMenu = false" />
          </div>
          <div class="menu-list">
            <van-collapse v-model="activeName">
              <template v-for="item in navList">
                <van-collapse-item class="menu-title" v-if="!item.path" :key="item.id" :title="item.alias" :name="item.id">
                  <van-cell class="menu-cnt" v-for="items in item.children" :key="items.id" :title="items.alias" is-link @click="jumpPage(items)" />
                </van-collapse-item>
                <template v-else>
                  <van-cell class="menu-title" :key="item.id" :title="item.alias" is-link @click="jumpPage(item)" />
                </template>
              </template>
            </van-collapse>
          </div>
        </van-popup>
      </div>
      <!-- 中间logo -->
      <div class="center-logo-box">
        <svg class="icon" aria-hidden="true" @click="jumpPage('/H5')">
          <use :xlink:href="isIndex && HomeScroll < lightMaxHeight ? '#eai-logo1' : '#eai-logo2'"></use>
        </svg>
      </div>
      <!-- 右侧操作按钮 -->
      <div class="right-optlist-box">
        <div class="cur-language" @click="showSelectLanguage = !showSelectLanguage">
          <span class="lang-text">{{ curLanguage }}</span>
          <i class="drop-icon">
            <svg class="icon" :class="showSelectLanguage ? 'up' : ''" aria-hidden="true">
              <use :xlink:href="isIndex && HomeScroll < lightMaxHeight ? '#eai-down1' : '#eai-down2'"></use></svg
          ></i>
        </div>
        <van-action-sheet class="drop-language" v-model="showSelectLanguage" :actions="LANGDISC" @select="changeLanguage" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      isIndex: true, // 是否是首页
      lightMaxHeight: 900, // 滚动条滚动N高度后，导航字体变色
      HomeScroll: 0, // 监听当前滚动条是否置顶，决定顶部导航是否需要换颜色
      curThemes: "light",
      isShowMenu: false, // 是否显示菜单弹出层
      activeName: [1],

      showSelectLanguage: false, // 是否显示切换语言下拉弹窗
      curLanguage: "中文",
      language: {},
      navList: [], // 导航数据
    };
  },
  created() {
    this.changeLanguage();
  },
  mounted() {
    window.addEventListener("scroll", this.listenerScroll, true);
  },
  watch: {
    $route: {
      handler(cur) {
        if (cur.path == "/H5" || cur.path == "/index" || cur.path == "/") {
          this.isIndex = true;
        } else {
          this.isIndex = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 监控滚动条滚动指定位置后，改变字体颜色和背景颜色
    listenerScroll(ev) {
      this.HomeScroll = window.pageYOffset;
    },

    // 修改语言
    changeLanguage(item) {
      let curLang = item?.value || "";
      curLang = curLang || window.localStorage.getItem("language");
      curLang = curLang != "null" ? curLang : "english";

      this.LANGDISC.map(item => {
        if (item.value == curLang) {
          this.curLanguage = item.name;
        }
      });

      this.COMFN.addStorageEvent(1, "language", curLang); // 保存到localStorage中
      this.language = Object.assign({}, this.LANGUAGES[curLang]);
      this.navList = this.language.homePage.navList;

      document.title = this.language.homePage.pageTitle;

      this.showSelectLanguage = false; // 关闭弹出层
    },
    // 点击导航跳转
    jumpPage(item_res) {
      this.isShowMenu = false;
      let url = "";
      if (item_res && typeof item_res == "string") {
        url = item_res;
        this.$router.push(url).catch(err => {});
      } else if (item_res?.path && Object.keys(item_res).length > 0) {
        let obj = {
          name: item_res.name,
        };
        if (item_res.params) {
          obj["params"] = item_res.params;
        }
        this.$router.push(obj).catch(err => {});
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.h5-topnav-box {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  font-size: $fs-16;
  @include color(color);
  @include color(card-bg, "background");
  .topnav-cnt-box {
    margin: 0 auto;
    padding: 0.12rem 0.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-menu-box {
      width: 25%;
      text-align: left;
      .btn-showMenu {
        font-size: $fs-30;
        @include color(color);
      }
      .menu-modal {
        height: 100%;
        width: 70%;
        @include color(color);
        .top-btn {
          padding: 0.14rem 0 0.1rem 0.14rem;
          .close-menu-modal {
            font-size: 0.3rem;
            @include color(title);
          }
        }
        ::v-deep .menu-list {
          .van-cell {
            font-size: $fs-16;
          }
          .menu-title {
            @include color(title);
            font-weight: bold;
          }
          .menu-cnt {
            @include color(color);
            font-weight: initial;
          }
        }
      }
    }
    .center-logo-box {
      cursor: pointer;
      .icon {
        width: 1.2rem;
        height: 0.44rem;
      }
    }
    .right-optlist-box {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: right;
      .cur-language {
        text-align: right;
        .lang-text {
          display: inline-block;
          vertical-align: middle;
        }
        .drop-icon {
          display: inline-block;
          vertical-align: middle;
          .icon {
            display: inline-block;
            vertical-align: middle;
            width: 0.18rem;
            height: 0.18rem;
          }
        }
      }
    }
    .up {
      transform: rotate(180deg);
    }
  }
  &.light {
    @include color(btn-color);
    @include color(themes1, "background");
    .topnav-cnt-box {
      .left-menu-box {
        .btn-showMenu {
          @include color(btn-color);
        }
      }
    }
  }
}
</style>
