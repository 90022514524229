<template>
  <div class="page-bottom-box">
    <div class="bottom-cnt-box">
      <div class="bottom-link">
        <div class="bottom-logo">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#eai-logo"></use>
          </svg>
        </div>
        <div class="link-list">
          <template v-for="item in language.homePage.navList">
            <span class="link-item" :key="item.id" v-if="item.isH5" @click="jumpPage(item)">{{ item.alias }}</span>
          </template>
        </div>
        <div class="desc-text">
          {{ language.homePage.bottom.text.recordText }}
        </div>
      </div>
      <div class="download-list">
        <div class="download-title">
          {{ language.homePage.bottom.text.downloadText }}
        </div>
        <div class="download-btn-list">
          <ul>
            <li class="btn-item" :data-url="language.homePage.download.google.url" @click="doDownload">
              <img :src="require('../assets/imgs/' + language.homePage.download.google.img)" alt="" />
            </li>
            <li class="btn-item" :data-url="language.homePage.download.apple.url" @click="doDownload">
              <img :src="require('../assets/imgs/' + language.homePage.download.apple.img)" alt="" />
            </li>
          </ul>
        </div>
        <div class="media-list">
          <div class="state-media-item" v-for="item in mediaList" :key="item.name" @click="jumpUrl(item.url)">
            <img :src="require('../assets/imgs/' + item.icon)" alt="" @mouseover="changeImgUrl($event, item.hicon)" @mouseleave="changeImgUrl($event, item.icon)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      language: {},
      mediaList: [
        {
          icon: "i_ins.png",
          hicon: "i_hins.png",
          url: "https://www.instagram.com/Onion_app/",
        },
        {
          icon: "i_fb.png",
          hicon: "i_hfb.png",
          url: "https://www.facebook.com/Onion_App-102384495645242",
        },
        {
          icon: "i_tw.png",
          hicon: "i_htw.png",
          url: "https://twitter.com/OnionApp",
        },
        {
          icon: "i_ytb.png",
          hicon: "i_hytb.png",
          url: "https://www.youtube.com/channel/UCx7BANqTq5CnKYnPSMGLAng",
        },
        {
          icon: "i_dy.png",
          hicon: "i_hdy.png",
          url: "https://www.tiktok.com/@no_insomnia555",
        },
      ],
    };
  },
  created() {
    this.getCurLanguage();
    window.addEventListener("setItem", e => {
      this.getCurLanguage();
    });
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    // 获取当前语言
    getCurLanguage() {
      let curLang = localStorage.getItem("language") || "english";
      this.language = Object.assign({}, this.LANGUAGES[curLang]);
    },

    // 下载app
    doDownload(ev) {
      window.open(ev.currentTarget.dataset.url);
    },

    // 点击导航跳转
    jumpPage(item_res) {
      let url = "";
      if (item_res && typeof item_res == "string") {
        url = item_res;
        this.$router.push(url).catch(err => {});
      } else if (item_res?.path && Object.keys(item_res).length > 0) {
        let obj = {
          name: item_res.name,
        };
        if (item_res.params) {
          obj["params"] = item_res.params;
        }
        this.$router.push(obj).catch(err => {});
      }
    },

    // 图片移入移出事件
    changeImgUrl(ev, url) {
      ev.target.src = require("../assets/imgs/" + url);
    },

    // 跳转到官媒
    jumpUrl(url) {
      var a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("target", "_blank");
      a.setAttribute("id", "camnpr");
      document.body.appendChild(a);
      a.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.page-bottom-box {
  width: 100%;
  @include color(bottom-bg, "background");
  @include color(bottom-color);
  text-align: left;
  .bottom-cnt-box {
    width: 80%;
    margin: 0 auto;
    padding: 0.56rem 0;
    font-size: $fs-16;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .bottom-link {
      flex: 1;
      .bottom-logo {
        width: 0.4rem;
        height: 0.4rem;
        margin-bottom: 0.3rem;
        .icon {
          width: 100%;
          height: 100%;
        }
      }
      .link-list {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        .link-item {
          margin-right: 0.24rem;
          cursor: pointer;
          &:hover {
            @include color(btn-color);
          }
        }
      }
      .desc-text {
        font-size: $fs-14;
      }
    }
    .download-list {
      width: 3.5rem;
      font-size: $fs-16;
      .download-title {
        margin-bottom: 0.16rem;
      }
      .download-btn-list {
        margin-bottom: 0.2rem;
        ul {
          display: flex;
          justify-content: center;
          align-items: center;
          li.btn-item {
            margin-right: 0.1rem;
            margin-bottom: 0.24rem;
            font-size: 0;
            flex: 1;
            img {
              cursor: pointer;
              width: 100%;
            }
          }
        }
      }
      .media-list {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        .state-media-item {
          flex: 1;
          margin-right: 0.15rem;
          &:last-child {
            margin-right: 0;
          }
          img {
            cursor: pointer;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
