<template>
  <div id="app" class="home-page">
    <TopNav v-if="!isH5"></TopNav>
    <H5TopNav v-if="isH5"></H5TopNav>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import TopNav from "./components/topNav.vue";
import H5TopNav from "./components/H5TopNav.vue";
export default {
  name: "App",
  components: { TopNav, H5TopNav },
  data() {
    return {
      isH5: false, // 是否是H5
    };
  },
  watch: {},
  created() {
    let curLang = localStorage.getItem("language");
    if (!curLang || curLang == "null") {
      localStorage.setItem("language", "english");
    }
    // 根据屏幕宽度自适应显示N个菜单
    window.addEventListener("resize", () => {
      //this.$nextTick(() => {
      this.checkWidth();
      //});
    });
  },
  mounted() {
    this.checkWidth();
  },
  methods: {
    checkWidth() {
      let flag = this.$route.path == "/H5" || this.$route.path == "/";
      let clientWidth = document.documentElement.clientWidth || document.body.clientWidth; // 当前屏幕宽度

      if (clientWidth <= 1024) {
        this.isH5 = true;
        if (flag) {
          this.$router.replace("/H5").catch(err => {});
        }
      } else {
        this.isH5 = false;
        if (flag) {
          this.$router.replace("/").catch(err => {});
        }
      }
    },
  },
};
</script>
<style lang="scss">
body.van-overflow-hidden {
  overflow: initial !important;
}
.home-page {
  width: 100%;
  height: 100%;
  @include color(card-bg, "background");
  .icon {
    fill: currentColor;
    overflow: hidden;
  }
}
</style>
