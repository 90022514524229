<template>
  <div class="H5-index-box">
    <div class="home-container" v-if="JSON.stringify(language) != '{}'">
      <div class="section page1">
        <div class="section-cnt-box">
          <div class="img-cnt-box">
            <div class="text-cnt-box">
              <div class="title-box">
                {{ language.homePage.mainIndex.page1.title }}
              </div>
              <div class="text-box">
                {{ language.homePage.mainIndex.page1.content }}
              </div>
              <div class="download-btn-list">
                <ul>
                  <li class="btn-item" v-if="!isIos" :data-url="language.homePage.download.google.url" @click="doDownload">
                    <img :src="require('../../assets/imgs/' + language.homePage.download.google.img)" alt="" />
                  </li>
                  <li class="btn-item" v-if="isIos" :data-url="language.homePage.download.apple.url" @click="doDownload">
                    <img :src="require('../../assets/imgs/' + language.homePage.download.apple.img)" alt="" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="img-box">
              <img class="bn-img" src="../../assets/imgs/img1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="section page2">
        <div class="section-cnt-box">
          <div class="img-cnt-box">
            <div class="text-cnt-box">
              <div class="title-box">
                {{ language.homePage.mainIndex.page2.title }}
              </div>
              <div class="text-box">
                {{ language.homePage.mainIndex.page2.content }}
              </div>
            </div>
            <div class="img-box">
              <img class="bn-img" src="../../assets/imgs/img2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="section page3">
        <div class="section-cnt-box">
          <div class="img-cnt-box">
            <div class="text-cnt-box">
              <div class="title-box">
                {{ language.homePage.mainIndex.page3.title }}
              </div>
              <div class="text-box">
                {{ language.homePage.mainIndex.page3.content }}
              </div>
            </div>
            <div class="img-box">
              <img class="bn-img" src="../../assets/imgs/img3.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="section page4">
        <div class="section-cnt-box">
          <div class="img-cnt-box line">
            <div class="text-cnt-box">
              <div class="title-box">
                {{ language.homePage.mainIndex.page4.title }}
              </div>
              <div class="text-box">
                {{ language.homePage.mainIndex.page4.content }}
              </div>
              <div class="link-text" @click="jumpPage('/plus')">
                {{ language.homePage.mainIndex.page4.linkText }}
              </div>
            </div>
            <div class="img-box">
              <img class="bn-img" src="../../assets/imgs/img4.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="section page5">
        <div class="section-cnt-box">
          <div class="img-cnt-box">
            <div class="text-cnt-box">
              <div class="title-box">
                {{ language.homePage.mainIndex.page5.title }}
              </div>
              <div class="text-box">
                {{ language.homePage.mainIndex.page5.content }}
              </div>
              <div class="link-text" @click="jumpPage('/gold')">
                {{ language.homePage.mainIndex.page5.linkText }}
              </div>
            </div>
            <div class="img-box">
              <img class="bn-img" src="../../assets/imgs/img5.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="section page6">
        <div class="section-cnt-box lastpage-cnt-box">
          <div class="bottom-box">
            <div class="img-cnt-box">
              <div class="title-box">
                {{ language.homePage.mainIndex.page6.title }}
              </div>
              <div class="text-box">
                {{ language.homePage.mainIndex.page6.content }}
              </div>
              <div class="img-box">
                <div class="last-img">
                  <img class="bn-img" src="../../assets/imgs/img6.png" alt="" />
                </div>
              </div>
            </div>
            <!-- 底部 -->
            <H5bottom />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import H5bottom from "../../components/H5bottom.vue";
export default {
  components: { H5bottom },
  data() {
    return {
      language: {}, // 当前语言包数据
      isIos: false, // 是否是IOS设备
    };
  },
  created() {
    this.getCurLanguage();
    window.addEventListener("setItem", e => {
      this.getCurLanguage();
    });
  },
  mounted() {
    this.appSource();
  },
  methods: {
    // 获取当前语言
    getCurLanguage() {
      let curLang = localStorage.getItem("language");
      curLang = curLang != "null" ? curLang : "english";
      this.language = Object.assign({}, this.LANGUAGES[curLang]);
    },
    //判断安卓和ios
    appSource() {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS) {
        this.isIos = true;
      } else {
        this.isIos = false;
      }
    },

    // 下载app
    doDownload(ev) {
      window.open(ev.currentTarget.dataset.url);
    },

    // 跳转页面
    jumpPage(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.H5-index-box {
  width: 100%;
  .home-container {
    width: 100%;
    .section {
      padding-top: 0.4rem;
      width: 100%;
      @include color(color);
      @include color(card-bg, "background");
      &.page1 {
        position: relative;
        z-index: 1;
        padding-top: 0.8rem;
        @include color(btn-color);
        @include color(themes1, "background");
        .section-cnt-box {
          .img-cnt-box {
            .text-cnt-box {
              .title-box {
                font-size: $fs-34;
                @include color(btn-color);
              }
              .text-box {
                @include color(btn-color);
              }
            }
          }
        }
      }
      &.page2 {
        margin-top: -1rem;
        position: relative;
        z-index: 2;
      }
      .section-cnt-box {
        width: 100%;
        margin: 0 auto;
        .img-cnt-box {
          width: 100%;
          .text-cnt-box {
            width: 100%;
            .title-box {
              font-size: $fs-30;
              line-height: 0.4rem;
              @include color(title);
              font-weight: bold;
              width: 83%;
              margin: 0 auto;
              margin-bottom: 0.14rem;
            }
            .text-box {
              font-size: $fs-16;
              line-height: 0.24rem;
              @include color(color);
              width: 83%;
              margin: 0 auto;
            }
            .link-text {
              font-size: $fs-16;
              line-height: 0.24rem;
              @include color(link);
              width: 83%;
              margin: 0 auto;
            }
            .download-btn-list {
              margin-top: 0.4rem;
              width: 100%;
              .btn-item {
                width: 1.4rem;
                margin: 0 auto;
                img {
                  width: 100%;
                }
              }
            }
          }
          .img-box {
            width: 100%;
            font-size: 0;
            .bn-img {
              width: 100%;
            }
          }
        }
        &.lastpage-cnt-box {
          width: 100%;
          .bottom-box {
            width: 100%;
            .img-cnt-box {
              width: 100%;
              .title-box {
                font-size: $fs-30;
                line-height: 0.4rem;
                @include color(title);
                font-weight: bold;
                width: 83%;
                margin: 0 auto;
                margin-bottom: 0.14rem;
              }
              .text-box {
                font-size: $fs-16;
                line-height: 0.24rem;
                @include color(color);
                width: 83%;
                margin: 0 auto;
              }
              .img-box {
                width: 100%;
                display: flex;
                justify-content: center;
                .last-img {
                  width: 100%;
                  text-align: center;
                  overflow: hidden;
                  font-size: 0;
                  .bn-img {
                    margin-left: -12.5%;
                    width: 125%;
                    height: initial;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
