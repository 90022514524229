<template>
  <div class="home-box">
    <div class="home-container" v-if="JSON.stringify(language) != '{}'">
      <full-page ref="fullpage" :options="options" id="fullpage">
        <div class="section page1">
          <div class="section-cnt-box">
            <div class="img-cnt-box">
              <div class="text-cnt-box">
                <div class="title-box">
                  {{ language.homePage.mainIndex.page1.title }}
                </div>
                <div class="text-box">
                  {{ language.homePage.mainIndex.page1.content }}
                </div>
                <div class="download-list">
                  <div class="download-title">
                    {{ language.homePage.mainIndex.downloadText }}
                  </div>
                  <div class="download-btn-list">
                    <ul>
                      <li
                        class="btn-item"
                        :data-url="language.homePage.download.google.url"
                        @click="doDownload"
                      >
                        <img
                          :src="
                            require('../../assets/imgs/' +
                              language.homePage.download.google.img)
                          "
                          alt=""
                        />
                      </li>
                      <li
                        class="btn-item"
                        :data-url="language.homePage.download.apple.url"
                        @click="doDownload"
                      >
                        <img
                          :src="
                            require('../../assets/imgs/' +
                              language.homePage.download.apple.img)
                          "
                          alt=""
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="img-box">
                <img class="bn-img" src="../../assets/imgs/img1.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="section page2">
          <div class="section-cnt-box">
            <div class="img-cnt-box">
              <div class="img-box">
                <img class="bn-img" src="../../assets/imgs/img2.png" alt="" />
              </div>
              <div class="text-cnt-box">
                <div class="title-box">
                  {{ language.homePage.mainIndex.page2.title }}
                </div>
                <div class="text-box">
                  {{ language.homePage.mainIndex.page2.content }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section page3">
          <div class="section-cnt-box">
            <div class="img-cnt-box">
              <div class="text-cnt-box">
                <div class="title-box">
                  {{ language.homePage.mainIndex.page3.title }}
                </div>
                <div class="text-box">
                  {{ language.homePage.mainIndex.page3.content }}
                </div>
              </div>
              <div class="img-box">
                <img class="bn-img" src="../../assets/imgs/img3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="section page4">
          <div class="section-cnt-box">
            <div class="img-cnt-box line">
              <div class="text-cnt-box">
                <div class="title-box">
                  {{ language.homePage.mainIndex.page4.title }}
                </div>
                <div class="text-box">
                  {{ language.homePage.mainIndex.page4.content }}
                </div>
                <div class="link-text" @click="jumpPage('/plus')">
                  {{ language.homePage.mainIndex.page4.linkText }}
                </div>
              </div>
              <div class="img-box">
                <img class="bn-img" src="../../assets/imgs/img4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="section page5">
          <div class="section-cnt-box">
            <div class="img-cnt-box">
              <div class="img-box">
                <img class="bn-img" src="../../assets/imgs/img5.png" alt="" />
              </div>
              <div class="text-cnt-box">
                <div class="title-box">
                  {{ language.homePage.mainIndex.page5.title }}
                </div>
                <div class="text-box">
                  {{ language.homePage.mainIndex.page5.content }}
                </div>
                <div class="link-text" @click="jumpPage('/gold')">
                  {{ language.homePage.mainIndex.page5.linkText }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section page6">
          <div class="lastpage-cnt-box">
            <div class="bottom-box">
              <div class="img-cnt-box">
                <div class="title-box">
                  {{ language.homePage.mainIndex.page6.title }}
                </div>
                <div class="text-box">
                  {{ language.homePage.mainIndex.page6.content }}
                </div>
                <div class="img-box">
                  <img class="bn-img" src="../../assets/imgs/img6.png" alt="" />
                </div>
              </div>
              <!-- 底部 -->
              <Bottom />
            </div>
          </div>
        </div>
      </full-page>
    </div>
  </div>
</template>

<script>
import Bottom from "../../components/bottom.vue";
export default {
  components: { Bottom },
  data() {
    const changeTopNav = (cur) => {
      if (cur.index == 0) {
        this.resetSetItem("scrollPageIndex", cur.index);
      } else {
        this.resetSetItem("scrollPageIndex", 1);
      }
    };
    return {
      language: {},
      options: {
        scrollingSpeed: 500,
        //licenseKey: 'YOUR_KEY_HEERE',
        menu: "#menu",
        sectionsColor: [],
        afterLoad(before, cur) {
          changeTopNav(cur);
        },
      },
    };
  },
  created() {
    this.getCurLanguage();
    window.addEventListener("setItem", (e) => {
      this.getCurLanguage();
    });
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    // 获取当前语言
    getCurLanguage() {
      let curLang = localStorage.getItem("language") || "english";
      this.language = Object.assign({}, this.LANGUAGES[curLang]);
    },

    // 下载app
    doDownload(ev) {
      window.open(ev.currentTarget.dataset.url);
    },

    // 跳转页面
    jumpPage(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
