<template>
  <div class="top-nav-box" :class="isIndex && HomeScroll == 0 ? 'light' : ''">
    <div class="top-nav-cnt">
      <!-- logo -->
      <div class="logo-box">
        <svg class="icon" aria-hidden="true" @click="jumpPage('/')">
          <use :xlink:href="isIndex && HomeScroll == 0 ? '#eai-logo1' : '#eai-logo2'"></use>
        </svg>
      </div>
      <!-- 导航 -->
      <div class="nav-list-box">
        <ul>
          <template v-for="item in navList">
            <li class="nav-item" :key="item.id" v-if="!item.isH5" @click="jumpPage(item)">
              <div class="menu-box">
                <template v-if="item.children">
                  <van-dropdown-menu class="drop-box drop-navmenu" v-model="curMenu" :overlay="false" :duration="0.1">
                    <van-dropdown-item v-model="curMenu" :title="item.alias" :options="item.children" @change="jumpPage" />
                  </van-dropdown-menu>
                </template>
                <template v-else>{{ item.alias }}</template>
              </div>
            </li>
          </template>
        </ul>
      </div>
      <!-- 操作列 -->
      <div class="top-btn-list">
        <ul>
          <li class="btn-item">
            <svg class="icon icon-language" aria-hidden="true">
              <use :xlink:href="isIndex && HomeScroll == 0 ? '#eai-language1' : '#eai-language2'"></use>
            </svg>

            <van-dropdown-menu class="drop-box sel-language" v-model="curLanguage" :overlay="false" :duration="0.1">
              <van-dropdown-item v-model="curLanguage" :options="LANGDISC" @change="changeLanguage" />
            </van-dropdown-menu>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      curMenu: "/index", // 当前路由地址
      isIndex: true, // 是否是首页
      HomeScroll: "", // 监听sessionStorage，决定顶部导航是否需要换颜色
      curThemes: "light",
      curLanguage: "english",
      language: {},
      navList: [], // 导航数据
    };
  },
  created() {
    this.changeLanguage();
    window.addEventListener("setItem", () => {
      this.HomeScroll = window.sessionStorage.getItem("scrollPageIndex");
    });
  },
  mounted() {},
  watch: {
    $route: {
      handler(cur) {
        this.curMenu = cur.path;
        if (cur.path == "/H5" || cur.path == "/index" || cur.path == "/") {
          this.isIndex = true;
        } else {
          this.isIndex = false;
        }
      },
      immediate: true,
    },
    navList(res) {
      // 此处有性能隐患
      if (res?.length > 0) {
        res.map(item => {
          if (item?.children?.length > 0) {
            item.children.map(items => {
              items["text"] = items.alias; // dropdown的组件只能识别text
              items["value"] = items.path; // dropdown的组件只能识别value
            });
          }
        });
      }
    },
  },
  methods: {
    // 当前语言
    changeLanguage(val) {
      let curLang = val || window.localStorage.getItem("language");
      curLang = curLang != "null" ? curLang : "english";
      this.LANGDISC.map(item => {
        if (item.value == curLang) {
          this.curLanguage = item.value;
        }
      });

      this.COMFN.addStorageEvent(1, "language", curLang); // 保存到localStorage中
      this.language = Object.assign({}, this.LANGUAGES[curLang]);
      this.navList = this.language.homePage.navList;

      document.title = this.language.homePage.pageTitle;
    },
    // 点击导航跳转
    jumpPage(item_res) {
      let url = "";
      if (item_res && typeof item_res == "string") {
        url = item_res;
        this.$router.push(url).catch(err => {});
      } else if (item_res?.path && Object.keys(item_res).length > 0) {
        let obj = {
          name: item_res.name,
        };
        if (item_res.params) {
          obj["params"] = item_res.params;
        }
        this.$router.push(obj).catch(err => {});
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.top-nav-box {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  font-size: $fs-16;
  @include color(color);
  @include color(card-bg, "background");
  .top-nav-cnt {
    width: 77%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ::v-deep .drop-box {
      &.drop-navmenu {
        .van-dropdown-item {
          width: 2rem;
          .van-cell__title {
            flex: 1;
          }
          .van-cell__value {
            -webkit-box-flex: initial;
            -webkit-flex: initial;
            flex: initial;
          }
        }
      }
      font-size: $fs-16;
      position: relative;
      .van-dropdown-menu__bar {
        display: inline;
        background: transparent;
        border: none;
        box-shadow: initial;
        .van-dropdown-menu__title {
          @include color(color);
          &::after {
            border-color: transparent transparent #333 #333;
          }
        }
      }
      .van-dropdown-item {
        position: fixed;
        left: initial;
        right: initial;
        bottom: initial;
        min-width: 1rem;
        border-radius: 8px;
        -webkit-box-shadow: 2px 4px 8px rgba(41, 41, 42, 0.2);
        -moz-box-shadow: 2px 4px 8px rgba(41, 41, 42, 0.2);
        box-shadow: 2px 4px 8px rgba(41, 41, 42, 0.2);
        .van-overlay {
          display: none;
        }
        .van-dropdown-item__content {
          position: initial;
          @include color(card-bg, "background");
          .van-cell {
            @include color(color);
            @include color(card-bg, "background");
          }
        }
      }
    }
    // logo
    .logo-box {
      cursor: pointer;
      .icon {
        width: 1.2rem;
        height: 0.44rem;
      }
    }

    // 导航
    .nav-list-box {
      flex: 1;
      padding: 0 0.16rem;
      ul {
        display: flex;
        align-items: center;
        li.nav-item {
          padding: 0.16rem;
          cursor: pointer;
        }
      }
    }

    // 操作列
    .top-btn-list {
      ul {
        display: flex;
        align-items: center;
        li.btn-item {
          .icon,
          .sel-language {
            display: inline-block;
            vertical-align: middle;
          }
          .icon-language {
            width: 0.2rem;
            height: 0.2rem;
          }
        }
      }
    }
  }
  &.light {
    @include color(btn-color);
    @include color(themes1, "background");
    ::v-deep .drop-box {
      .van-dropdown-menu__bar {
        .van-dropdown-menu__title {
          @include color(btn-color);
          &::after {
            border-color: transparent transparent #fff #fff;
          }
        }
      }
      .van-dropdown-item {
        .van-dropdown-item__content {
          position: initial;
          @include color(themes1, "background");
          .van-cell {
            @include color(btn-color);
            @include color(themes1, "background");
          }
        }
      }
    }
    .top-btn-list {
      ul {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
